@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: 0 0 0;
  --white: 255 255 255;
  --accent: 7 176 86;
  --accent-200: 45 196 111;
  --primary: var(--black);
  --primary-200: 60 60 60;
  --secondary: var(--white);
  --secondary-200: 236 236 236;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 246, 242);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
